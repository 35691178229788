
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { Head, Notes } from 'mdx-deck'
import { theme } from './theme'
export const themes = [theme];
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  themes
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>Hi</title>
    </Head>
    <h1>{`Hello!`}</h1>
    <hr></hr>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;